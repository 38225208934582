import { Path, PATH_SYNONYMS } from "../constants/Path";
import { FALLBACK, Params } from "../constants/Params";
import { Page, PAGE_DEFINITION } from "../constants/Page";
import Locale from "../../modules/common/constants/Locale";
import { ensureSingleQuestionMarkForParams } from "./pathBuilderHelpers";

const isParam = (value: string): value is Params => {
  return FALLBACK[value as Params] !== undefined;
};

export function getPathArray(route: (Path | Params)[], locale: Locale | "universal"): string[] {
  return route.map(routeItem => {
    if (isParam(routeItem)) {
      return `:${routeItem}`;
    } else {
      const pathItems = PATH_SYNONYMS[routeItem];
      return typeof pathItems === "string" ? pathItems : pathItems[locale];
    }
  });
}

export const buildStaticPagePath = (page: Page, language: Locale, params?: string) => {
  const pathArray = getPathArray(PAGE_DEFINITION[page].route, language);
  // first item might be language param
  const [firstParam, ...restPath] = pathArray;

  const staticPath =
    firstParam === `:${Params.lang}` ? `/${[language, ...restPath].join("/")}` : `/${pathArray.join("/")}`;
  const fixedParams = params ? ensureSingleQuestionMarkForParams(params) : "";
  return `${staticPath}${fixedParams}`;
};
