import { DateTimeParam } from "use-query-params";
import Locale from "../../modules/common/constants/Locale";
import { Page } from "../constants/Page";
import { getPathForLanguage, Path } from "../constants/Path";
import { SearchParams } from "../constants/SearchParams";
import { VEHICLE_TAB_INDEX } from "../../dealer-detail/constants/tab";
import { buildQueryParams } from "./queryParamHelper";
import { buildStaticPagePath } from "./pageHelpers";

export const DEALER_LOCATOR_LOCATION_SEPARATOR = "|";

export type StaticPagePathFragment = Path.general | Path.yourVehicle;

type ItemDetailPathArguments = {
  slug: string | undefined | null;
  id: string | number;
  language?: Locale;
};

type ItemDetailTabArgument = {
  tab?: number;
};

const buildSlugIdPath = (slug: string | null | undefined, id: number | string): string => {
  const slugItems = slug?.split("-") ?? [];
  if (slugItems[slugItems.length - 1] !== `${id}`) {
    slugItems.push(`${id}`);
  }
  return slugItems.join("-");
};

/**
 * Returns a set of path builders for the Carmarket main app.
 * Whenever creating any URL (e.g. <Link /> or <link alternate…/>) use these path builders.
 * If the required path builder does not exist - extend these.
 *
 * These paths must not be used in whitelabel instances.
 * @param currentLanguage
 * @param baseUrl
 * @param authBaseUrl
 */
export const getPathBuilders = (currentLanguage: Locale, baseUrl: string, authBaseUrl: string) => {
  const buildAbsolutePath = (relativePath: string, base = baseUrl): string => {
    return base + relativePath;
  };

  const staticPagePathBuilder = ({
    page,
    language = currentLanguage,
    params,
  }: {
    page: Page;
    language?: Locale;
    params?: string;
  }) => {
    return buildStaticPagePath(page, language, params);
  };

  const relativePathBuilders = {
    // to be used for simple page urls where no params are needed
    pagePath: staticPagePathBuilder,
    homePath: ({ language, params }: { language?: Locale; params?: string }) =>
      staticPagePathBuilder({
        language,
        page: Page.home,
        params,
      }),
    searchPath: ({
      q,
      language = currentLanguage,
      params,
    }: { q?: string; params?: string; language?: Locale } = {}) => {
      return `/${language}/${getPathForLanguage(Path.search, language)}${
        params ? ensureSingleQuestionMarkForParams(params) : buildQueryParams([SearchParams.searchQuery, q])
      }`;
    },
    dealerLocatorPath: (args: { q?: string; location?: { lat: string; lng: string }; params?: string } = {}) => {
      return `/${currentLanguage}/${getPathForLanguage(Path.dealerLocator, currentLanguage)}${
        args.params
          ? args.params
          : buildQueryParams(
              [SearchParams.searchQuery, args.q],
              [
                SearchParams.location,
                args.location
                  ? `${args.location.lat}${DEALER_LOCATOR_LOCATION_SEPARATOR}${args.location.lng}`
                  : undefined,
              ],
            )
      }`;
    },
    dealerDetailPath: ({
      slug,
      id,
      tab,
      params,
      language = currentLanguage,
    }: ItemDetailPathArguments & ItemDetailTabArgument & { params?: string }) => {
      const queryParams = buildQueryParams([SearchParams.pageTab, `${tab}`]);
      const fixedParams = params && tab === VEHICLE_TAB_INDEX ? ensureSingleAndForParams(params) : "";
      return `/${language}/${getPathForLanguage(Path.dealer, language)}/${buildSlugIdPath(slug, id)}${tab ? queryParams : ""}${fixedParams}`;
    },
    vehicleDetailPath: ({
      slug,
      id,
      language = currentLanguage,
      params,
    }: ItemDetailPathArguments & { params?: string }) => {
      const fixedParams = params ? ensureSingleQuestionMarkForParams(params) : "";
      return `/${language}/${getPathForLanguage(Path.vehicle, language)}/${buildSlugIdPath(slug, id)}${fixedParams}`;
    },
    brandPath: ({ slug, language = currentLanguage }: { slug: string; language?: Locale }) =>
      `/${language}/${getPathForLanguage(Path.brand, language)}/${slug}`,
    brandListPath: ({
      page,
      language = currentLanguage,
      params,
    }: {
      page?: number;
      language?: Locale;
      params?: string;
    }) =>
      staticPagePathBuilder({
        page: Page.brandList,
        language,
        params: params ? params : buildQueryParams([SearchParams.page, page ? `${page}` : undefined]),
      }),
    modelPath: ({
      brandSlug,
      modelSlug,
      language = currentLanguage,
    }: {
      brandSlug: string;
      modelSlug: string;
      language?: Locale;
    }) => `/${language}/${getPathForLanguage(Path.brand, language)}/${brandSlug}/${modelSlug}`,
    magazinePath: ({
      page,
      language = currentLanguage,
      params,
    }: { page?: number; language?: Locale; params?: string } = {}) =>
      staticPagePathBuilder({
        page: Page.magazineOverview,
        language,
        params: params ? params : buildQueryParams([SearchParams.page, page ? `${page}` : undefined]),
      }),
    magazineArticlePath: ({ slug, id, language = currentLanguage }: ItemDetailPathArguments) =>
      `/${language}/${getPathForLanguage(Path.magazine, language)}/${slug}-${id}`,
    promotionsPath: ({
      page,
      language = currentLanguage,
      params,
    }: { page?: number; language?: Locale; params?: string } = {}) =>
      staticPagePathBuilder({
        page: Page.promotionOverview,
        language,
        params: params ? params : buildQueryParams([SearchParams.page, page ? `${page}` : undefined]),
      }),
    promotionPath: ({ slug, id, language = currentLanguage }: ItemDetailPathArguments) =>
      `/${language}/${getPathForLanguage(Path.promotion, language)}/${buildSlugIdPath(slug, id)}`,
    comparisonPath: ({ vehicleIds }: { vehicleIds: string[] }) =>
      `/${currentLanguage}/${getPathForLanguage(Path.vehicleComparison, currentLanguage)}${buildQueryParams([
        SearchParams.vehicleId,
        vehicleIds,
      ])}`,
    favoritesPath: ({ language, params }: { language?: Locale; params?: string }) =>
      staticPagePathBuilder({
        language,
        page: Page.favorites,
        params,
      }),
    savedSearchesPath: ({ language, params }: { language?: Locale; params?: string }) =>
      staticPagePathBuilder({
        language,
        page: Page.savedSearches,
        params,
      }),
    cockpitPath: ({ language, params }: { language?: Locale; params?: string }) =>
      staticPagePathBuilder({
        language,
        page: Page.cockpit,
        params,
      }),
    staticPagePath: ({ rootPath, slug }: { rootPath: StaticPagePathFragment; slug: string }) =>
      `/${currentLanguage}/${getPathForLanguage(rootPath, currentLanguage)}/${slug}`,
    testDrivePath: ({
      slug,
      id,
      date,
      language = currentLanguage,
    }: {
      slug: string;
      id: string;
      date?: Date;
      language?: Locale;
    }) =>
      `/${language}/${getPathForLanguage(Path.ffuTestDrive, language)}/${buildSlugIdPath(slug, id)}${buildQueryParams([
        SearchParams.date,
        // typecast is ok as the return value is too broad for the narrow usage
        DateTimeParam.encode(date) as string,
      ])}`,
    leaseVehiclePath: ({
      slug,
      id,
      totalRuntime,
      language = currentLanguage,
    }: {
      slug: string;
      id: string;
      totalRuntime?: string;
      language?: Locale;
    }) =>
      `/${language}/${getPathForLanguage(Path.leasingIntro, language)}/${buildSlugIdPath(slug, id)}${buildQueryParams([
        SearchParams.totalRuntime,
        totalRuntime,
      ])}`,
    vehiclePurchasePath: ({
      slug,
      id,
      language = currentLanguage,
    }: {
      slug: string;
      id: string;
      totalRuntime?: string;
      language?: Locale;
    }) => `/${language}/${getPathForLanguage(Path.vehiclePurchase, language)}/${buildSlugIdPath(slug, id)}`,
    leaseVehicleApplicationPath: ({ id, language = currentLanguage }: { id: string; language?: Locale }) =>
      `/${language}/${getPathForLanguage(Path.leasingApplication, language)}/${id}`,
    leaseVehicleRequestPath: ({ id, language = currentLanguage }: { id: string; language?: Locale }) =>
      `/${language}/${getPathForLanguage(Path.leasingRequest, language)}/${id}`,
    generalInquiryPath: ({
      slug,
      id,
      vehicleId,
      language = currentLanguage,
    }: {
      slug: string;
      id: number;
      vehicleId?: number;
      language?: Locale;
    }) =>
      `/${language}/${getPathForLanguage(Path.ffuGeneralInquiry, language)}/${buildSlugIdPath(
        slug,
        id,
      )}${buildQueryParams([SearchParams.vehicleId, vehicleId ? `${vehicleId}` : undefined])}`,
    vehicleEvaluationIntroPath: ({
      language = currentLanguage,
      params,
    }: { language?: Locale; params?: string } = {}): string =>
      `/${language}/${getPathForLanguage(Path.vehicleEvaluationIntro, language)}${params ?? ""}`,
    vehicleEstimationPath: ({
      language = currentLanguage,
      params,
    }: { language?: Locale; params?: string } = {}): string =>
      `/${language}/${getPathForLanguage(Path.vehicleEstimation, language)}${params ?? ""}`,
    vehicleEvaluationPath: ({
      language = currentLanguage,
      params,
    }: { language?: Locale; params?: string } = {}): string =>
      `/${language}/${getPathForLanguage(Path.ffuVehicleEvaluation, language)}${params ?? ""}`,
    tourChecklistsPath: ({ language, params }: { language?: Locale; params?: string }) =>
      staticPagePathBuilder({
        page: Page.checklist,
        language,
        params,
      }),
    tourCompetitionFormPath: ({ language, params }: { language: Locale; params?: string }) =>
      staticPagePathBuilder({
        page: Page.competitionForm,
        language,
        params,
      }),
    newsletterSubscribePath: ({ language = currentLanguage, email }: { language?: Locale; email?: string }) =>
      staticPagePathBuilder({
        page: Page.newsletterSubscribe,
        language,
        params: buildQueryParams([SearchParams.email, email]),
      }),
    pathFromParentSlugs: ({ language = currentLanguage, parentSlugs }: { language?: Locale; parentSlugs: string[] }) =>
      `/${language}/${parentSlugs.join("/")}`,
    matchmakerPath: ({ language = currentLanguage, params }: { language?: Locale; params?: string }) =>
      staticPagePathBuilder({
        page: Page.matchmaker,
        language,
        params,
      }),
  };

  const pathBuilders = {} as {
    [key in keyof typeof relativePathBuilders]: (
      args?: { absolute?: boolean; base?: string } & Parameters<(typeof relativePathBuilders)[key]>[0],
    ) => string;
  };

  type PathBuilderKey = keyof typeof relativePathBuilders;

  Object.entries(relativePathBuilders).forEach(([key, builder]) => {
    const pathBuilderKey = key as PathBuilderKey;

    pathBuilders[pathBuilderKey] = ({
      absolute = false,
      base,
      ...rest
    }: { absolute?: boolean; base?: string } = {}) => {
      // "as any" because getting a type for "rest" wouldn't be feasible and is irrelevant everywhere else
      return absolute ? buildAbsolutePath(builder(rest as any), base) : builder(rest as any);
    };
  });

  return {
    ...pathBuilders,
    loginPath: () => buildAbsolutePath("/oauth2/authorize", authBaseUrl),
    registerPath: () => buildAbsolutePath("/oauth2/register", authBaseUrl),
    logoutPath: () => buildAbsolutePath("/oauth2/logout", authBaseUrl),
    accountEditPath: () => buildAbsolutePath("/account/edit", authBaseUrl),
    whitelabelSearchPath: () => "/",
    whitelabelVehicleDetailPath: ({ slug, id }: ItemDetailPathArguments) => `/vehicle/${buildSlugIdPath(slug, id)}`,
    buildAbsolutePath,
  };
};

export const ensureSingleQuestionMarkForParams = (params: string) => {
  return `?${params.replace(/^\?+/, "")}`;
};

export const ensureSingleAndForParams = (params: string) => {
  return `&${params.replace(/^\&+/, "")}`;
};
