import { getPathBuilders } from "../helpers/pathBuilderHelpers";
import { AUTH_BASE_URL } from "../../auth/constants";
import useLanguage from "./useLanguage";

const APP_BASE_URL = import.meta.env.VITE_APP_BASE_URL!;

function usePathBuilder() {
  const currentLanguage = useLanguage();

  return getPathBuilders(currentLanguage, APP_BASE_URL, AUTH_BASE_URL);
}

export default usePathBuilder;
