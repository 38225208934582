import React, { HTMLAttributeAnchorTarget } from "react";
import i18next from "i18next";
import CMLogo from "../../../template/public/assets/img/layout/inline/carmarket-logo.svg?react";
import ContextLink from "../../common/components/ContextLink";

type Props = {
  showSlogan?: boolean;
  target?: HTMLAttributeAnchorTarget;
  context: "header" | "advertising";
  link: string;
};

const Logo: React.FC<Props> = ({ showSlogan = false, context, target = "_self", link }) => {
  return (
    <ContextLink className={`${context}__logo`} target={target} to={link}>
      <div className="logo">
        <CMLogo />
      </div>
      {showSlogan ? <div className="logo-text">{i18next.t("CARS FROM EXPERTS")}</div> : null}
    </ContextLink>
  );
};

export default Logo;
