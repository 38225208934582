import { Context, createContext, useContext } from "react";

export const createNullableContext = <T>(contextName: string): [Context<T | null>, () => T] => {
  const Context = createContext<T | null>(null);

  const useNullableContext = () => {
    const context = useContext(Context);

    if (!context) {
      throw new Error(`ensure to provide ${contextName}`);
    }

    return context;
  };

  return [Context, useNullableContext];
};
