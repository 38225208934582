import { useParams } from "react-router-dom";
import i18next from "i18next";
import { FALLBACK, Params } from "../constants/Params";
import Locale, { LOCALE_TRANSLATORS } from "../../modules/common/constants/Locale";

function useLanguage(): Locale {
  const params = useParams();
  const lang = params[Params.lang] ?? i18next.resolvedLanguage ?? FALLBACK[Params.lang];
  if (LOCALE_TRANSLATORS[lang as Locale] === undefined) {
    return FALLBACK[Params.lang] as Locale;
  }
  return lang.toLowerCase() as Locale;
}

export default useLanguage;
