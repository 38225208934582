import { useCallback, useLayoutEffect, useRef } from "react";

/**
 * useStableFunction based on useEvent implementation.
 *
 * The main difference is semantics: useEvent is meant to handle side-effects whereas this implementation is mean to provide stable but updated function references.
 *
 * @param handler
 */
// Function is explicitly desired here
// eslint-disable-next-line @typescript-eslint/ban-types
function useStableFunction<T extends Function>(handler: T): T {
  // the difference between useEvent (as a means to handle side-effects) and useStableFunctionRef should be implemented by having two separate hooks
  const handlerRef = useRef<T | null>(handler);

  // In a real implementation, this would run before layout effects
  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  const callback = useCallback((...args: any) => {
    if (!handlerRef.current) {
      throw new Error("event called before initialization");
    }
    // In a real implementation, this would throw if called during render
    const fn = handlerRef.current;
    return fn(...args);
  }, []);
  return callback as unknown as T;
}

export default useStableFunction;
