import * as Sentry from "@sentry/react";

// init sentry client, see https://docs.sentry.io/platforms/javascript/#integrating-the-sdk
// only enable sentry in production or testing bundle
if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
  });
}
