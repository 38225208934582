import React, { ComponentProps, forwardRef, ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HistoryState } from "../../../router/constants/State";
import { createNullableContext } from "../helpers/contextCreator";
import useStableFunction from "../hooks/useStableFunction";

type LinkContextType = {
  value: string;
  setter: (value: string) => void;
};

export const PENDING_LINK_CONTEXT = "pending";
export const [LinkContext, useLinkContext] = createNullableContext<LinkContextType>("LinkContext");

export const LinkContextRootProvider = ({ children }: { children: ReactNode }) => {
  const [linkContext, setLink] = useState<string>(PENDING_LINK_CONTEXT);
  const updateLink = useStableFunction(setLink);

  return <LinkContext.Provider value={{ value: linkContext, setter: updateLink }}>{children}</LinkContext.Provider>;
};

/**
 * This context is intended to set the "magic back button" label for subsequent pages.
 * @param children
 * @param label Label which will be set in the history state of the next page.
 * @constructor
 */
export const LinkContextProvider: React.FC<{ children?: ReactNode; label: string }> = ({ children, label }) => {
  const { setter } = useLinkContext();
  useEffect(() => {
    setter(label);

    return () => setter(PENDING_LINK_CONTEXT);
  }, [label, setter]);

  return children;
};

type Props = ComponentProps<typeof Link>;

/**
 * Component to wrap a Link component with a context label.
 */
const ContextLink: React.FC<Props> = forwardRef(({ state = {}, ...rest }, ref) => {
  const { value } = useLinkContext();
  const isLabelSet = value !== PENDING_LINK_CONTEXT;

  return (
    <Link
      ref={ref}
      {...rest}
      state={isLabelSet ? { ...state, [HistoryState.CONTEXT_DEPENDENT_LINK_LABEL]: value } : state}
    />
  );
});

export default ContextLink;
